import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "templateKey": "sv-team",
  "title": "Vårt team",
  "seo": {
    "title": "-",
    "descr": "-"
  },
  "icon": "/img/linkedin.png",
  "employees": [{
    "image": "/img/fred.jpg",
    "name": "Fred Alpsten",
    "role": "CEO",
    "email": "fred@zimply.ai",
    "linkedin": "https://www.linkedin.com/in/fred-alpsten-524304/"
  }, {
    "image": "/img/daniel.jpg",
    "name": "Daniel Baldwin",
    "role": "COO",
    "email": "daniel@zimply.ai",
    "linkedin": "https://www.linkedin.com/in/danielbaldwinswe/"
  }, {
    "image": "/img/robin.png",
    "name": "Robin Magnussen",
    "role": "Sales Executive",
    "email": "robin@zimply.ai",
    "linkedin": "https://www.linkedin.com/in/robin-magnussen-199917111/?miniProfileUrn=urn%3Ali%3Afs_miniProfile%3AACoAABwT_UUB8NmoIAx2WqlljJB5fZWVs_v1quc"
  }, {
    "image": "/img/nagy.jpg",
    "name": "Peter Nagy",
    "role": "Creative Director",
    "email": "peter@zimply.ai",
    "linkedin": "https://www.linkedin.com/in/pnagy1/"
  }, {
    "name": "Isabelle Norman",
    "role": "Tech Lead RPA",
    "email": "isabelle@zimply.ai",
    "linkedin": "https://www.linkedin.com/in/isabelle-norman-75b9b093/",
    "image": "/img/isabelle.jpg"
  }, {
    "name": "Lars Von Scheele",
    "role": "Product Manager & Developer",
    "email": "lars@zimply.ai",
    "linkedin": "https://www.linkedin.com/in/lars-von-scheele/",
    "image": "/img/lars.jpg"
  }, {
    "name": "Christofer Eriksson",
    "role": "Developer & Business Analyst",
    "email": "christofer@zimply.ai",
    "linkedin": "https://www.linkedin.com/in/christofer-eriksson-lantz-8a7208108/",
    "image": "/img/christofer.jpg"
  }, {
    "name": "Aurore Borg",
    "role": "Developer",
    "email": "aurore@zimply.ai",
    "linkedin": "https://www.linkedin.com/in/aurore-borg-14127341/",
    "image": "/img/aurore.jpg"
  }, {
    "name": "Sebastian André",
    "role": "Product Manager & Developer",
    "email": "sebastian@zimply.ai",
    "linkedin": "https://www.linkedin.com/in/sebastian-andr%C3%A9-82b2361a5/",
    "image": "/img/sebastian.jpg"
  }, {
    "name": "Matilda Andersson",
    "role": "Developer",
    "email": "matilda@zimply.ai",
    "linkedin": "https://www.linkedin.com/in/matilda-andersson-10825ab4/",
    "image": "/img/matilda.jpg"
  }, {
    "image": "/img/annie.jpg",
    "name": "Annie Cedersjö",
    "role": "Product Owner",
    "email": "annie@zimply.ai",
    "linkedin": "https://www.linkedin.com/in/annie-cedersjo-intelligentautomation/"
  }, {
    "linkedin": "https://www.linkedin.com/in/stefan-lorentzon-b0b47310a/",
    "name": "Stefan Lorentzon",
    "role": "Business Analyst",
    "email": "stefan@zimply.ai",
    "image": "/img/stefan.jpg"
  }]
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      